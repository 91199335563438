.content{
  margin-top: 30px;
  padding:20px;
  background-color: #fff;
}

.side-image{
  width:180px;
  display:block;
  margin-left: auto;
  margin-right: auto;
}