.zajindan {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80vw;
  height: 36.8rem;
  height: 36.8vw;
  z-index: 0;
  top: 50%;
}
.zajindan__bed {
  display: block;
  width: 100%;
}
.zajindan__box {
  position: absolute;
  left: 18%;
  top: -2%;
  width: 64%;
  height: 38%;
}
.zajindan__egg {
  position: absolute;
  width: 50%;
  left: 25%;
  top: -145%;
  transform-origin: 50% 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.zajindan__egg-img {
  display: block;
  width: 100%;
}
.zajindan__egg-img.hidden {
  visibility: hidden;
}
.zajindan__egg-shell-1 {
  position: absolute;
  left: -12%;
  bottom: 8%;
  width: 60%;
  animation: eggShell1Anim 0.25s linear forwards;
}
.zajindan__egg-shell-2 {
  position: absolute;
  right: -12%;
  bottom: 8%;
  width: 60%;
  animation: eggShell2Anim 0.25s linear forwards;
}
.zajindan__hammer {
  position: absolute;
  left: 58%;
  top: -20%;
  width: 60%;
  z-index: 2;
  transform-origin: 100% 100%;
  animation: hammerAnim 0.2s ease-in forwards;
}

.zajindan__hammer_still {
  position: absolute;
  left: 80%;
  top: -306%;
  width: 30%;
  z-index: 2;
  transform-origin: 100% 100%;
}

@keyframes eggShell1Anim {
  0% {
    transform: translate(2px, -12px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes eggShell2Anim {
  0% {
    transform: translate(-2px, -12px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes hammerAnim {
  0% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}