.container {
  height: 100vh;
  background-image: url(https://choin.com.cn/projects/yinger/asserts/img/game-bg.png);
  background-size: 100% 100%;
}

.time-used {
  width: 7.19rem;
  height: 1.66rem;
  background: #FFEFC3;
  box-shadow: 0.13rem 0.13rem 0rem 0rem #008A77, inset 0rem 0rem 0rem 0.13rem #FFFFFF;
  border-radius: 0.84rem;
  border: 0.06rem solid #00334A;
  padding: 0.31rem 0.63rem;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  float: right;
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.score {
  width: 7.19rem;
  height: 1.66rem;
  background: #FFEFC3;
  box-shadow: 0.13rem 0.13rem 0rem 0rem #008A77, inset 0rem 0rem 0rem 0.13rem #FFFFFF;
  border-radius: 0.84rem;
  border: 0.06rem solid #00334A;
  padding: 0.31rem 0.63rem;
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.start-confirm {
  font-size: 1.13rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #003043;
  position: relative;
  top: -2%;
  transform: translateY(-50%);
  height: 100%;
  display: flex;
  align-items: center;
}

.start-wrap {
  width: 100vw;
  height: 140vw;
  margin-top: 50vh;
  transform: translate(0,-50%);
  width: 100%;
  text-align: center;
  
}

.start-game {
  width: 6.61rem;
  height: 3.2rem;
  margin-top: 1rem;
}

.start {
  width: 9.81rem;
  height: 3.94rem;
  background: #FFEFC3;
  box-shadow: 0.25rem 0.25rem 0rem 0rem #008A77, inset 0rem 0rem 0rem 0.38rem #FFFFFF;
  border-radius: 1.97rem;
  border: 0.19rem solid #00334A;
  margin: 0 auto;

}

.game-over {
  width: 16.88rem;
  height: 14.16rem;
  background: #FFFFFF;
  box-shadow: 0rem 0rem 1.56rem 0rem rgba(0, 0, 0, 0.0500);
  border-radius: 0.5rem;
  background-image: url('../../assets/images/game/Ribbons.png');
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: 50% -130%;
  padding-top: 0px !important;
}

.over-confirm {
  font-size: 1.13rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #003043;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lucky-draw-content-score {
  width: 100%;
  height: 1.41rem;
  line-height: 1.41rem;
  text-align: center;
  font-size: 1rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #003043;
  margin-top: 1.88rem;
}

.lucky-draw-content-message {
  width: 100%;
  text-align: center;
  height: 1.94rem;
  font-size: 1.38rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #E54151;
  line-height: 1.94rem;
  margin-top: 0.63rem;
}

.not-winning {
  width: 6.94rem;
  height: 3.13rem;
  background: #FFEFC3;
  box-shadow: 0rem 0rem 1.56rem 0rem rgba(0, 0, 0, 0.0500), 0.25rem 0.25rem 0rem 0rem #008A77, inset 0rem 0rem 0rem 0.38rem #FFFFFF;
  border-radius: 1.97rem;
  border: 0.19rem solid #00334A;
  color: #003043;
  display: flex;
  align-items: center;
  justify-content: center
}


.winning-message {
  margin-top: 1.88rem;
  font-size: 1.38rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #E54151;
  text-align: center;
}

.prize-text {
  margin-top: 0.63rem;
  font-size: 1.38rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #003043;
  text-align: center;

}

.winning-btn {
  width: 14.88rem;
  height: 2.75rem;
  background: #63A03C;
  box-shadow: 0rem 0rem 1.56rem 0rem rgba(0, 0, 0, 0.0500);
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 2.75rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1.88rem;
}

.user-info-dialog {
  width: 16.88rem;
  /* height: 28.16rem; */
  background: #FFFFFF;
  box-shadow: 0rem 0rem 1.56rem 0rem rgba(0, 0, 0, 0.0500);
  border-radius: 0.5rem;
  background-image: url('../../assets/images/game/Ribbons.png');
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: 50% -13%;
  padding-top: 0px !important;

}

.user-info-form {
  color: #333333;
  margin-top: 1rem;
}

.user-info-input {
  --font-size: 0.88rem
}

.user-info-label {
  --font-size: 0.88rem;
  font-size: 0.88rem;
  font-weight: 400;
  color: #000000;
}

.wrap {
  width: 100%;
  text-align: center;
}

.tip {
  width: 90vw;
  height: 130vw;
}

/* .section_83 {
  box-shadow: inset 0px 0px 0px 12px rgba(255, 255, 255, 1);
  background-color: rgba(255, 239, 195, 1);
  border-radius: 63px;
  width: 326px;
  height: 138px;
  border: 6px solid rgba(0, 51, 74, 1);
  margin: -6px -6px -6px -6px;
  padding: 38px 50px 38px 50px;
}

.image-text_8 {
  width: 214px;
}

.group_70 {
  width: 50px;
  height: 50px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge35163fc556cc869d6ea5b20f9336f68df5226945fc386a7afe596e101e59861)
    100% no-repeat;
  background-size: 100% 100%;
}

.text-group_2 {
  color: rgba(0, 48, 67, 1);
  font-size: 36px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
} */

.section_83 {
  width: 157px;
  height: 63px;
  background: #FFEFC3;
  box-shadow: 4px 4px 0px 0px rgba(0,138,119,1), inset 0px 0px 0px 6px rgba(255,255,255,1);
  border-radius: 32px;
  border: 3px solid #00334A;
}

.image-text_8 {
  width: 214px;
}

.group_70 {
  width: 50px;
  height: 50px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge35163fc556cc869d6ea5b20f9336f68df5226945fc386a7afe596e101e59861)
    100% no-repeat;
  background-size: 100% 100%;
}

.text-group_2 {
  color: rgba(0, 48, 67, 1);
  font-size: 36px;
  font-family: PingFangSC-Semibold;
  text-align: left;
  white-space: nowrap;
  line-height: 50px;
}

.add-tip {
  position: absolute;
  z-index: 2;
  left: 50vw;
  top: 50vh;
  color: red;
  font-size: 18px;
}

