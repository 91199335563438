.App-header{
background-color: #fff !important;
}

.main-header{
  background-color: #fff !important;
  padding:0 130px !important;
  height:60px;
  line-height:60px;
  margin-left:auto;
  margin-right:auto;
}

.top-header{
  height:40px;
  padding-left: 10%;
  padding-right: 10%;
  display:block;
  background-color: #f5f5f5;
  line-height: 40px;
}

.button-group{
  float:right;
  margin-top: 5px;
}

.App-logo {
height: 60px;
width: 60px;
float:left;
}

@media (prefers-reduced-motion: no-preference) {
.App-logo {
  animation: App-logo-spin infinite 20s linear;
}
}

@keyframes App-logo-spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}


.title{
  line-height:64px !important;
  display: inline;
  color:#03a9f4 !important;
}

.search-input {
  width:400px !important;
  line-height:64px;
  margin-top: 17px;
  margin-left: 37px;
}

.main-menu {
  padding-left: 6% !important;
  padding-right: 6% !important;
  background-color: #1976d2 !important;
  color: #f5f5f5 !important;
}